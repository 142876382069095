import React, { Component } from "react"
import { graphql } from "gatsby"
import HeaderDark from "../components/header/header-dark"
import SEO from "../components/seo"
import PortableText from "@sanity/block-content-to-react"
import {
  ContentWrapper,
  ContentIceberg,
  ContentBody,
  ContentTitle,
} from "../styled-components/inner-page-styles"
import { FloatingText } from "../styled-components/floating-text"
import { Global, css } from "@emotion/core"
import { TweenLite, Power3 } from "gsap"
import Video from "../components/video/video"

class StudioPost extends Component {
  componentDidMount() {
    document.querySelector("body").style.backgroundColor = "#eff0f2"

    if (window.matchMedia("(min-width: 1024px)").matches) {
      setTimeout(() => {
        const headers = document.querySelectorAll(".animate-in h2")
        let count = 1
        headers.forEach(h2 => {
          Object.assign(h2.nextElementSibling.style, {
            marginTop: `-${h2.clientHeight + 8}px`,
          })
          h2.id = "heading-" + count
          count = count + 1
        })
      }, 500)
    }

    const wrappers = document.querySelectorAll(".animate-in *:not(h2)")
    const wrappers_h2 = document.querySelectorAll(".animate-in h2")

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          TweenLite.to(entry.target, 1.4, {
            opacity: 1,
            y: 0,
            ease: Power3.easeInOut,
          })
        }
      })
    })

    const observer_h2 = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          TweenLite.to(entry.target, 1.4, {
            opacity: 1,
            x: 0,
            ease: Power3.easeInOut,
            onComplete: () => {
              entry.target.classList.add("visible")
            },
          })
        }
      })
    })

    if (window.matchMedia("(min-width: 1024px)").matches) {
      wrappers.forEach(content => {
        TweenLite.set(content, {
          opacity: 0,
          y: "64px",
        })
        observer.observe(content)
      })

      wrappers_h2.forEach(content => {
        TweenLite.set(content, {
          opacity: 0,
          x: "-64px",
        })
        observer_h2.observe(content)
      })
    }

    document.querySelectorAll("video").forEach(video => {
      video.addEventListener("click", e => {
        if (e.target.paused) {
          e.target.play()
        } else {
          e.target.pause()
        }
      })
    })
  }

  render() {
    const { data } = this.props
    const post = data.sanityStudioPost

    return (
      <>
        <Global
          styles={css`
            body {
              background-color: #eff0f2;
            }

            iframe {
              border: none;
              width: 100%;
              min-height: 600px;
              font-family: "Montserrat", sans-serif;
            }
          `}
        />
        <SEO title={post.title} />
        <HeaderDark />
        <FloatingText
          css={css`
            color: #1e1e1e;
          `}
        >
          we can help
        </FloatingText>
        <ContentIceberg>{post.iceberg}</ContentIceberg>
        <ContentWrapper className="content-wrapper">
          <ContentTitle>{post.title}</ContentTitle>
          <ContentBody className="animate-in">
            <PortableText
              blocks={post._rawContent}
              serializers={{
                types: {
                  ptImage: ({ node }) => (
                    <img src={node.image.asset.url} alt={node.alt} />
                  ),
                  ptVideo: ({ node }) => (
                    <Video
                      vidSrc={node.video.asset.url}
                      autoplay={false}
                      controls={false}
                      muted={false}
                      poster={node.poster.asset.url}
                    />
                  ),
                },
              }}
            />
          </ContentBody>
          <iframe
            title="Signup Form"
            src="https://cdn.forms-content.sg-form.com/cc0731bf-49ce-11ea-a929-3ad0de62a54d"
          />
        </ContentWrapper>
      </>
    )
  }
}

export const query = graphql`
  query($slug: String!) {
    sanityStudioPost(slug: { current: { eq: $slug } }) {
      title
      iceberg
      _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default StudioPost
